import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  map,
  Observable,
} from 'rxjs';

import { environment } from '@Environment';

import { UploadFileCodes } from '../enums/upload-file-codes.enum';
import { Country } from '../types/country.type';
import { ListDetails } from '../types/list-management.type';
import { Role } from '../types/role.type';
import { UploadFile } from '../types/upload-file.type';
import { User } from '../types/user.type';

@Injectable()
export class SystemRepository {
  public constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  public getRoles(): Observable<Role[]> {
    return this.httpClient.get<ListDetails<Role>>(
      `${environment.authApiUrl}v1/system/roles?page=1&limit=100`,
    ).pipe(
      map((roles: ListDetails<Role>) => roles?.data ?? []),
    );
  }

  public getCountries(): Observable<Country[]> {
    return this.httpClient.get<Country[]>(`${environment.authApiUrl}v1/system/countries`);
  }

  public downloadDocument(apiUrl: string, id: string): Observable<string> {
    return this.httpClient.get<{ url: string }>(`${apiUrl}v1/system/documents/${id}/download`)
      .pipe(map(({ url }) => url));
  }

  public postDocumentsUpload(
    apiUrl: string,
    code: UploadFileCodes,
    formData: FormData,
  ): Observable<UploadFile> {
    return this.httpClient.post<UploadFile>(
      `${apiUrl}v1/system/documents/upload/${code}`,
      formData,
    );
  }

  public postDocumentsBulkUpload(
    apiUrl: string,
    code: UploadFileCodes,
    formData: FormData,
  ): Observable<UploadFile[]> {
    return this.httpClient.post<{ createdDocuments: UploadFile[] }>(
      `${apiUrl}v1/system/documents/bulk-upload/${code}`,
      formData,
    ).pipe(
      map(({ createdDocuments }) => createdDocuments ?? []),
    );
  }

  public getUsers(params: HttpParams): Observable<User[]> {
    return this.httpClient.get<ListDetails<User>>(
      `${environment.authApiUrl}v1/users/public_search`,
      { params },
    ).pipe(
      map((users: ListDetails<User>) => (
        users.data.map((user: User) => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
        }))
      )),
    );
  }
}
