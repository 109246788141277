import {
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

import { baseEvents } from '@App/core/store/base/base.events';
import {
  GetCountriesSuccessProps,
  GetRolesSuccessProps,
} from '@App/core/types/system-props.type';

export const SystemActions = createActionGroup({
  source: 'System',
  events: {
    ...baseEvents,
    'Get Roles': emptyProps(),
    'Get Roles Success': props<GetRolesSuccessProps>(),
    'Get Countries': emptyProps(),
    'Get Countries Success': props<GetCountriesSuccessProps>(),
  },
});
