import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import {
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';

import { UploadFileCodes } from '@App/core/enums/upload-file-codes.enum';
import { SystemRepository } from '@App/core/repositories/system.repository';
import { BaseFacade } from '@App/core/store/base/base.facade';
import { Country } from '@App/core/types/country.type';
import { Role } from '@App/core/types/role.type';
import { UploadFile } from '@App/core/types/upload-file.type';
import { User } from '@App/core/types/user.type';

import { SystemActions } from './system.actions';
import {
  selectCountries,
  selectError,
  selectPending,
  selectRoles,
} from './system.feature';
import { SystemState } from './system.state';

@Injectable()
export class SystemFacade extends BaseFacade<SystemState, typeof SystemActions> {
  public selectRoles$: Observable<Role[]> = this.store.pipe(select(selectRoles));
  public selectPending$: Observable<boolean> = this.store.pipe(select(selectPending));
  public selectCountries$: Observable<Country[]> = this.store.pipe(select(selectCountries));

  public constructor(
    private readonly systemRepository: SystemRepository,
  ) {
    super(SystemActions, selectError, selectPending);
  }

  public getRoles(): Observable<void> {
    return this.selectRoles$
      .pipe(
        switchMap((roles: Role[]) => {
          if (roles.length > 0) {
            return of(undefined);
          }

          return this.dispatchAction(SystemActions.getRoles());
        }),
      );
  }

  public getCountries(): Observable<void> {
    return this.selectCountries$
      .pipe(
        switchMap((countries: Country[]) => {
          if (countries.length > 0) {
            return of(undefined);
          }

          return this.dispatchAction(SystemActions.getCountries());
        }),
      );
  }

  public downloadDocument(apiUrl: string, id: string): Observable<string> {
    return this.systemRepository.downloadDocument(apiUrl, id)
      .pipe(
        take(1),
      );
  }

  public postDocumentsUpload(
    apiUrl: string,
    code: UploadFileCodes,
    formData: FormData,
  ): Observable<UploadFile> {
    return this.systemRepository.postDocumentsUpload(apiUrl, code, formData)
      .pipe(
        take(1),
      );
  }

  public postDocumentsBulkUpload(
    apiUrl: string,
    code: UploadFileCodes,
    formData: FormData,
  ): Observable<UploadFile[]> {
    return this.systemRepository.postDocumentsBulkUpload(apiUrl, code, formData)
      .pipe(
        take(1),
      );
  }

  public getUsers(params: HttpParams): Observable<User[]> {
    return this.systemRepository.getUsers(params)
      .pipe(
        take(1),
      );
  }
}
