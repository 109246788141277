import {
  createFeature,
  createReducer,
  on,
} from '@ngrx/store';

import {
  ErrorProps,
  PendingProps,
} from '@App/core/store/base/base.props';
import {
  GetCountriesSuccessProps,
  GetRolesSuccessProps,
} from '@App/core/types/system-props.type';

import { SystemActions } from './system.actions';
import { SystemState } from './system.state';

const systemInitialState: SystemState = {
  roles: [],
  error: false,
  countries: [],
  pending: false,
};

export const {
  name,
  reducer,
  selectRoles,
  selectError,
  selectPending,
  selectCountries,
} = createFeature({
  name: 'system',
  reducer: createReducer(
    systemInitialState,
    on(
      SystemActions.setError,
      (state: SystemState, { error }: ErrorProps) => ({
        ...state,
        error,
      }),
    ),
    on(
      SystemActions.setPending,
      (state: SystemState, { pending }: PendingProps) => ({
        ...state,
        pending,
      }),
    ),
    on(
      SystemActions.getRolesSuccess,
      (state: SystemState, { roles }: GetRolesSuccessProps) => ({
        ...state,
        roles,
      }),
    ),
    on(
      SystemActions.getCountriesSuccess,
      (state: SystemState, { countries }: GetCountriesSuccessProps) => ({
        ...state,
        countries: countries ?? [],
      }),
    ),
  ),
});
